'use client'
import {motion} from "framer-motion";

export default function GenericWhiteButton({href, title, className}: GenericButtonProps) {
	const handleScroll = (e) => {
		const targetStartIndex = e.currentTarget.getAttribute("href").indexOf("#");
		if (targetStartIndex === -1) return;

		e.preventDefault();
		const targetId = e.currentTarget.getAttribute("href").substring(1);
		const targetElement = document.getElementById(targetId);
		if (targetElement) {
			targetElement.scrollIntoView({behavior: "smooth"});
		}
	};

	return (
		<motion.div
		className={`flex justify-center items-center bg-header-button-border-gradient p-[2px] rounded-full shadow-header-button`}
		>
			<motion.a
				onClick={handleScroll}
				href={href}
				className={`flex flex-col justify-center bg-header-button-gradient rounded-full p-4 bg-linear-white-to-transparent text-center text-white font-normal min-w-max px-5 text-base md:text-lg h-10 md:h-10 ${className}`}>
				{title}
			</motion.a>
		</motion.div>
	)
}

interface GenericButtonProps {
	href?: string,
	title?: string
	className?: string
}
